import React from 'react';
import {List, Link} from '@mui/material/';

class Menu extends React.Component {
  render() {
    return <List id="menu">
      {this.props.sections.map((section, i) => {
        return (<li key={`submenu-${section}`} data-menuanchor={section} className={(i === 0) ? 'active' : ''}>
          <Link underline="none" href={`#${section}`} style={{ textTransform: 'capitalize' }}>{`${section.replace('-', ' ')}`}</Link>
        </li>)
      })}
    </List>;
  }
}

export default Menu;