import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material/';
import objServices from '../content/services.json';

export const mesServices = (
  <Grid container justifyContent="center" alignItems="center" spacing={4} my={5}>
    {objServices.services.service.map(elm =>
      <Grid item xs={12} sm={4} md={4} lg={2}>
       <Card className="my-services-card">
        <CardMedia
          component="img"
          alt={elm.alt}
          height="200"
          image={elm.url}
        />
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            {elm.title}
          </Typography>
          <Typography className="card-text" variant="body2" color="text.secondary">
            {elm.content}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    )} 
  </Grid>
);
