import React from 'react';
import { Typography, Box, Grid } from '@mui/material/';

class MultipleSlides extends React.Component {
  render() {
    return <>
      <Box className="slide" id="section-mes-projets">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Mes projets</Typography>
            <Typography>Voici un petit aperçu de l'ensemble des projets que j'ai pu contribuer.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-1">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Scrum master / PM (thermohydraulique)</Typography>
            <Typography>Je devais intégrer Scrum sur certains projets. J'accompagnais les équipes dans une démarche agile.</Typography>
            <Typography>Je devais également mettre en place des cérémonies agiles. (Sprint Planning, Daily, Sprint Review, Sprint Retrospective)</Typography>
            <Typography>J'avais également à ma charge la gestion du backlog et la restitution des besoins.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-2">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Accompagnement agile en interne</Typography>
            <Typography>J'étais en charge d'intégrer Scrum dans une équipe de développeurs pour réaliser une application interne.</Typography>
            <Typography>Les cérémonies agiles sont de nouvelles réunions qui ont été implémentées.</Typography>
            <Typography>Je gérais également le backlog de l'équipe.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-3">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Responsable technique pour une mutuelle</Typography>
            <Typography>Pour éviter de mauvaises surprises certains processus agiles ont été intégrés.</Typography>
            <Typography>J'occupais le poste de Responsable technique, je participais au développement de l'application mais également aux différents ateliers avec le client.</Typography>
            <Typography>Je m'occupais des tests fonctionnels mais également de la livraison de l'application sur les différents environnements du client.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-4">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Responsable technique pour la Défense</Typography>
            <Typography>En tant que responsable technique, je devais m'occuper de la partie développement, tests et livraisons.</Typography>
            <Typography>J'avais des ateliers techniques avec le client pour conceptualiser l'ensemble de ses demandes.</Typography>
            <Typography>Je travaillais avec des équipes parisiennes et j'avais des déplacements chez le client pour des démonstrations de l'application.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-5">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section">
            <Typography variant="h1">Projet personnel avec des Raspberry Pi</Typography>
            <Typography>Installation d'un serveur web pour la réalisation de ce fameux portfolio.</Typography>
            <Typography>Installation d'une fonctionnalité permettant de cast le contenu du PC sur la TV.</Typography>
            <Typography>Montage d'une "Gameboy" et d'une "Playstation" en installant Retropie pour l'émulation de jeux vidéo retro.</Typography>
            <Typography>Utilisation de Pi-hole, paramétrage de la Box et de la Pi pour bloquer les publicités.</Typography>
          </Box>
        </Grid>
      </Box>
      <Box className="slide" id="slide-6">
        <Box className="fp-bg"></Box>
        <Grid container justifyContent="center" alignItems="center">
          <Box className="intro project-section" id="others-projets-section">
            <Typography variant="h1">Et bien d'autres encore ! ;)</Typography>
            <Typography>D'autres projets seront diffusés prochainement.</Typography>
          </Box>
        </Grid>
      </Box>
    </>
  }
}

export default MultipleSlides;