import { Grid, Typography, Link, Button } from '@mui/material/';
import DownloadIcon from '@mui/icons-material/Download';
export const introduction = (
  <>
    <Grid item xs={12} sx={{ display: { md: 'block' } }}>
      <Typography>Anciennement développeur fullstack je me suis spécialisé dans l'aspect organisationnel et gestion de projets MOE.</Typography>
    </Grid>
    <Grid id="download-button-cv" item xs={12} sx={{ display: { xs: 'block' }, my: '2rem' }}>
      <Link target="_blank" href="/files/CV_FREELANCE_DINTANAVONG.pdf">
        <Button variant="contained" startIcon={<DownloadIcon />}>Télécharger mon CV</Button>
      </Link>
    </Grid>
  </>
);
